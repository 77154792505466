import React from 'react'

// CUSTOM COMPONENTS
import CustomTextField from 'components/Customs/CustomTextField'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'

// MUI MATERIALS
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './filterViewUseStyles'

const FilterView = (props) => {
  const {
    search,
    setSearch,
    filterTemperature,
    setFilterTemperature,
    filterPower,
    setFilterPower,
    filterStatus,
    setFilterStatus,
    filterOperation,
    setFilterOperation,
  } = props

  const classes = useStyles()

  return (
    <div className={classes.filterViewRoot}>
      <Grid
        container
        spacing={'10px'}
        alignItems='flex-end'
      >
        {/* SEARCH */}
        <Grid 
          item
          xs={12} lg={4}
        >
          <CustomTextField
            className={classes.filterSearch}
            placeholder='Search'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconSearch/>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* TEMPERATURE FILTER */}
        <Grid
          item
          xs={6} sm={3} lg={2}
        >
          <Typography
            variant='subtitle2'
            align='center'
            className={classes.listItemGrayText}
          >
            Temperature
          </Typography>
          <ToggleButtonGroup
            color='primary'
            value={filterTemperature}
            exclusive
            onChange={(event, newValue) => setFilterTemperature(newValue)}
            fullWidth
          >
            <ToggleButton value={0}>{'< -15.0'}</ToggleButton>
            <ToggleButton value={1}>{'> -15.0'}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {/* POWER FILTER */}
        <Grid
          item
          xs={6} sm={3} lg={2}
        >
          <Typography
            variant='subtitle2'
            align='center'
            className={classes.listItemGrayText}
          >
            Power
          </Typography>
          <ToggleButtonGroup
            color='primary'
            value={filterPower}
            exclusive
            onChange={(event, newValue) => setFilterPower(newValue)}
            fullWidth
          >
            <ToggleButton value={0}>OFF</ToggleButton>
            <ToggleButton value={1}>ON</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {/* STATUS FILTER */}
        <Grid
          item
          xs={6} sm={3} lg={2}
        >
          <Typography
            variant='subtitle2'
            align='center'
            className={classes.listItemGrayText}
          >
            Status
          </Typography>
          <ToggleButtonGroup
            color='primary'
            value={filterStatus}
            exclusive
            onChange={(event, newValue) => setFilterStatus(newValue)}
            fullWidth
          >
            <ToggleButton value={0}>NOT OK</ToggleButton>
            <ToggleButton value={1}>OK</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {/* OPERATION FILTER */}
        <Grid
          item
          xs={6} sm={3} lg={2}
        >
          <Typography
            variant='subtitle2'
            align='center'
            className={classes.listItemGrayText}
          >
            Operation
          </Typography>
          <ToggleButtonGroup
            color='primary'
            value={filterOperation}
            exclusive
            onChange={(event, newValue) => setFilterOperation(newValue)}
            fullWidth
          >
            <ToggleButton value={0}>NOT OP</ToggleButton>
            <ToggleButton value={1}>OPER</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
}

export default FilterView