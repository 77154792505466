import React from 'react'

// CONTEXTS
import { PageHomeContextProvider } from 'contexts/PageHomeContext'

// PAGES
import SignIn from 'pages/SignIn/SignIn'
import Home from 'pages/Home/Home'

// REACT ROUTER DOM
import { 
  BrowserRouter as Router, 
  Route, 
  Switch, 
} from 'react-router-dom'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/sign-in'>
          <SignIn/>
        </Route>
        <Route exact path='/'>
          <PageHomeContextProvider>
            <Home/>
          </PageHomeContextProvider>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
