// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  homeContentRoot: {
		width: '100%',
    height: '100%',
    flex: 1,
    backgroundColor: 'white',
    padding: '10px 20px 20px',
    [theme.breakpoints.only('xs')]: {
      height: 'unset',
    },
    [theme.breakpoints.only('sm')]: {
      height: 'unset',
    },
  },
}))

export default useStyles