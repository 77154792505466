import React, { useContext } from 'react'

// CONTEXTS
import { PageHomeContext } from 'contexts/PageHomeContext'

// CUSTOM COMPONENTS
import CustomTabs from 'components/Customs/CustomTabs'
import CustomTab from 'components/Customs/CustomTab'

// STYLES
import useStyles from './homeTabUseStyles'

const TabBar = () => {
  const classes = useStyles()

  const { 
    selectedTab, 
    changeSelectedTab,
  } = useContext(PageHomeContext)

  return (
    <div className={classes.homeTabRoot}>
      <CustomTabs
        value={selectedTab}
        onChange={(event, newValue) => changeSelectedTab(event, newValue)}
        indicatorColor='secondary'
        textColor='inherit'
      >
        <CustomTab label='Warehouses'/>
        <CustomTab label='Detail'/>
        <CustomTab label='Logs'/>
      </CustomTabs>
    </div>
  )
}

export default TabBar
