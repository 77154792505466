import React from 'react'
import PropTypes from 'prop-types'

// MUI MATERIALS
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

const Toast = (props) => {
  const {
    open,
    setToast,
    severity,
    message,
  } = props

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast(null)
  }

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleToastClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert 
        elevation={6}
        variant='filled'
        onClose={handleToastClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

Toast.defaultProps = {
  open: false,
  severity: 'success',
  message: '',
}

Toast.propTypes = {
  open: PropTypes.bool,
  setToast: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
  message: PropTypes.string,
}

export default Toast
