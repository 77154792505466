import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// MUI MATERIALS
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MATERIAL UI ICONS
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// STYLES
import useStyles from './rightSectionUseStyles'

// UTILS
import { postSignInApi } from 'services/signIn/postSignInApi'
import { setUsernameToLocalStorage } from 'utils/localStorage'

const RightSection = () => {
  // const TAG = 'SignIn'

  const classes = useStyles()

  const history = useHistory()

	const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')
  const [ isPasswordShown, setIsPasswordShown ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(false)

	const dataValidation = () => {
		setErrorMessage(null)
		
		if(email === '' || password === '') {
			setErrorMessage('Please fill all fields')
		}
		else {
			handleSignInUser()
		}
	}

  const handleSignInUser = async () => {
    setIsLoading(true)
    const response = await postSignInApi(email, password)
    if(response.status === 200) {
      setUsernameToLocalStorage(email)
      history.push('/')
    }
    else if(response.status === 401) {
      setErrorMessage('Wrong password')
    }
    else if(response.status === 404) {
      setErrorMessage('Invalid email')
    }
    else {
      setErrorMessage('Sorry, there is something wrong. Please contact us.')
    }
    setIsLoading(false)
  }

  useEffect(() => {
		setErrorMessage(null)
	}, [email, password])

  return (
    <Grid 
      item 
      xs={12} md={6} lg={4}
    >
      <div className={classes.rightSection}>
        <div className={classes.rightContent}>
          {/* TITLE */}
          <Typography
            variant='h4'
            className={classes.title}
          >
            Welcome to Lacak.io
          </Typography>

          {/* LOADING */}
          {isLoading &&
          <LinearProgress className={classes.loading}/>}

          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes.errorMessage}
          >
            {errorMessage}
          </Alert>}

          {/* EMAIL TEXT FIELD */}
          <TextField
            className={classes.emailTextField}
            disabled={isLoading}
            label='Email'
            variant='outlined'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {/* PASSWORD TEXT FIELD */}
          <FormControl
            variant='outlined'
            className={classes.passwordTextField}
          >
            <InputLabel htmlFor='outlined-adornment-password'>
              Password
            </InputLabel>
            <OutlinedInput
              id='outlined-adornment-password'
              type={isPasswordShown ? 'text' : 'password'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setIsPasswordShown(current => !current)}
                    edge='end'
                  >
                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
            />
          </FormControl>

          {/* SIGN IN BUTTON */}
          <Button
            className={classes.signInButton}
            disabled={isLoading}
            onClick={() => dataValidation()}
          >
            <Typography
              variant='subtitle1'
              className={classes.signInText}
            >
              Sign In
            </Typography>
          </Button>
        </div>
      </div>
    </Grid>
  )
}

export default RightSection
