const keyUsername = 'lacak-glico-wings-email'

export const setUsernameToLocalStorage = (inputUsername) => {
  localStorage.setItem(keyUsername, inputUsername)
}

export const readUsernameFromLocalStorage = () => { 
  return localStorage.getItem(keyUsername)
}

export const removeUsernameFromLocalStorage = () => {
  localStorage.removeItem(keyUsername)
}