import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

// CONSTANTS
import customTheme from 'constants/customTheme'
import firebaseConfig from 'constants/firebaseConfig'

// FIREBASE
import { initializeApp } from 'firebase/app'

// MATERIAL UI DATA GRIDS
import { LicenseInfo } from '@mui/x-data-grid-pro'

// MUI MATERIALS
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

initializeApp(firebaseConfig)

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE)

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </ThemeProvider>,
  document.getElementById('root')
)