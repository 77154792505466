export const getTrackerSensorsApi = async () => {
  // const TAG = 'getTrackerSensorsApi'

  const baseUrl = process.env.REACT_APP_BASE_API_NEW
  const completeUrl = `${baseUrl}/glico/reading/get_all`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

export const detailGetTrackerSensorsApi = async () => {
  const rawData = await getTrackerSensorsApi()
  let newData = []
  let order = 1

  // ADD ALL ITEMS TO A NEW ARRAY WHICH EACH SENSOR LABEL PROPERTY INCLUDES 'TEMP FREEZER'
  for(let i = 0; i < rawData.length; i++){
    let rawDataItem = rawData[i]
    rawDataItem.sensorLabelId = rawDataItem.sensorLabel.split(' - ')[1] // EXAMPLE: '13205571'

    // 1. CHECK IF THE NEW ARRAY IS EMPTY AND THE SENSOR LABEL PROPERTY INCLUDES 'TEMP FREEZER'
    if(newData.length === 0 && rawDataItem.sensorLabel.includes('Temp Freezer')){
      rawDataItem.no = order
      rawDataItem.freezer = rawDataItem.sensorLabel.split(' - ')[0]
      rawDataItem.power = rawDataItem.powerValue >= 11.0 ? 'ON' : 'OFF'
      rawDataItem.status = rawDataItem.sensorValue >= -15.0 || rawDataItem.power === 'OFF' ? 'NOT OK' : 'OK'
      newData.push(rawDataItem)
    }
    else{
      // 2. CHECK IF THE SENSOR LABEL ID IS AVAILABLE IN THE ARRAY
      let isNewItem = 0
      for(let j = 0; j < newData.length; j++){
        let newDataItem = newData[j]
        if(newDataItem.sensorLabelId === rawDataItem.sensorLabelId){
          isNewItem++
        }
      }

      // 3. ADD THE ITEM IF THE SENSOR LABEL ID PROPERTY IS NOT AVAILABLE IN THE ARRAY
      if(isNewItem === 0 && rawDataItem.sensorLabel.includes('Temp Freezer')){
        order++
        rawDataItem.no = order
        rawDataItem.freezer = rawDataItem.sensorLabel.split(' - ')[0]
        rawDataItem.power = rawDataItem.powerValue >= 11.0 ? 'ON' : 'OFF'
        rawDataItem.status = rawDataItem.sensorValue >= -15.0 || rawDataItem.power === 'OFF' ? 'NOT OK' : 'OK'
        newData.push(rawDataItem)
      }
    }
  }

  // ADD BATTERY VALUE TO EVERY CORRESPONDING ITEM INSIDE THE NEW ARRAY
  for(let i = 0; i < rawData.length; i++){
    let rawDataItem = rawData[i]
    if(rawDataItem.sensorLabel.includes('Battery Sensor')){
      for(let j = 0; j < newData.length; j++){
        let newDataItem = newData[j]
        if(newDataItem.sensorLabelId !== rawDataItem.sensorLabelId){
          newDataItem.battery = rawDataItem.sensorValue
        }
      }
    }
  }

  return newData
}