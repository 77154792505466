import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

// COMPONENTS
import Header from 'components/Header/Header'
import HomeTab from './HomeTab/HomeTab'
import HomeContent from './HomeContent/HomeContent'
import Toast from 'components/Toast/Toast'

// CONTEXTS
import { PageHomeContext } from 'contexts/PageHomeContext'

// STYLES
import useStyles from './homeUseStyles'

// UTILS
import { readUsernameFromLocalStorage } from 'utils/localStorage'

const Home = () => {
  const { 
    toast, 
    changeToast, 
  } = useContext(PageHomeContext)

  const classes = useStyles()

  if(!readUsernameFromLocalStorage()) {
    return <Redirect to='/sign-in'/>
  }
  return (
    <div className={classes.homeRoot}>
      <Header/>
      
      <HomeTab/>
      <HomeContent/>

      {toast &&
      <Toast
        open={toast.open}
        setToast={changeToast}
        severity={toast.severity}
        message={toast.message}
      />}
    </div>
  )
}

export default Home
