// CONSTANTS
import colors from 'constants/colors'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	rightSection: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 650,
	},
	rightContent: {
		[theme.breakpoints.only('xs')]: {
			width: '80%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '65%',
		},
	},
	title: {
		fontWeight: 600,
		marginBottom: 50,
	},
	caption: {
		fontWeight: 500,
		color: colors.darkgray,
		marginBottom: 50,
	},
	loading: {
		marginBottom: 25,
	},
	errorMessage: {
		marginBottom: 25,
		boxSizing: 'border-box',
		width: '100%',
		borderRadius: 4,
	},
	signUpLink: {
		color: colors.dodgerblue,
		textDecoration: 'none',
		fontWeight: 600,
		cursor: 'pointer',
	},
	emailTextField: {
		width: '100%',
		marginBottom: 25,
	},
	passwordTextField: {
		width: '100%',
		marginBottom: 50,
	},
	signInButton: {
		backgroundColor: colors.dodgerblue,
		color: 'white',
		textTransform: 'none',
		height: 55,
		width: '100%',
		borderRadius: 4,
		marginBottom: 40,
		'&:hover': {
			backgroundColor: colors.dodgerblue,
		},
	},
	signInText: {
		fontWeight: 600,
	},
}))

export default useStyles