import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageHomeContext } from 'contexts/PageHomeContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Customs/CustomDataGrid'
import CustomSwitch from 'components/Customs/CustomSwitch'

// FIREBASE
import { ref, set, child, get } from 'firebase/database'

// MU MATERIALS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'

// SERVICES
import { detailGetTrackerSensorsApi } from 'services/home/getTrackerSensorsApi'
import { detailGetTrackerRulesApi } from 'services/home/getTrackerRulesApi'
import { getUpdateRulesApi } from 'services/home/getUpdateRulesApi'

// STYLES
import useStyles from './contentWarehousesUseStyle'

// UTILS
import { combineTrackerSensorsAndRulesData } from 'utils/home/combineTrackerSensorsAndRulesData'
import { filterCombinedDataByEmail } from 'utils/home/filterCombinedDataByEmail'
import { getWarehouseAndSuspendedList } from 'utils/home/getWarehouseAndSuspendedList'

const ContentWarehouses = () => {
  const { 
    changeToast,
    firebaseDatabase,
  } = useContext(PageHomeContext)

  const classes = useStyles()

  const [ fullData, setFullData ] = useState([])
  const [ tableData, setTableData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  const fetchDataFromApis = async () => {
    setIsLoading(true)

    const trackersData = await detailGetTrackerSensorsApi()
    // console.log(trackersData)
    if(!trackersData.status) {
      const rulesData = await detailGetTrackerRulesApi()
      // console.log(rulesData)
      if(!rulesData.status) {
        const combinedData = combineTrackerSensorsAndRulesData(trackersData, rulesData)
        // console.log(combinedData)
        setFullData(combinedData)
        const filteredData = filterCombinedDataByEmail(combinedData)
        // console.log(filteredData)
        const warehouseAndSuspendedList = getWarehouseAndSuspendedList(filteredData)
        // console.log(warehouseAndSuspendedList)
        setTableData(warehouseAndSuspendedList)
      }
      else {
        changeToast({
          open: true,
          severity: 'error',
          message: rulesData.error,
        })
      }
    }
    else {
      changeToast({
        open: true,
        severity: 'error',
        message: trackersData.error,
      })
    }

    setIsLoading(false)
  }

  const onCustomSwitchChange = async (inputParams) => {
    setIsLoading(true)
    
    // INITIAL VALUE = FALSE, FINAL VALUE = TRUE
    if(!inputParams.row.value) {
      // SAVE FULL DATA TO FIREBASE DATABASE
      set(
        ref(firebaseDatabase, 'glico-wings-database/warehouses/'),
        getWarehouseAndSuspendedList(fullData),
      )

      // CHANGE RULE BY ID FOR ITEM = FALSE
      for(let i = 0; i < inputParams.row.sensorIdList.length; i++) {
        const sensorIdItem = inputParams.row.sensorIdList[i]
        const valueItem = inputParams.row.valueList[i]

        if(!valueItem) {
          await getUpdateRulesApi(sensorIdItem)
        }
      }

      // FETCH DATA
      await fetchDataFromApis()
    }
    // INITIAL VALUE = TRUE, FINAL VALUE = FALSE
    else if(inputParams.row.value) {
      // READ DATA FROM FIREBASE DATABASE
      let warehousesDataFromFirebaseDatabase
      get(child(ref(firebaseDatabase), 'glico-wings-database/warehouses'))
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          warehousesDataFromFirebaseDatabase = snapshot.val()

          // CHANGE RULE BY ID FOR ITEM = FALSE
          for(let i = 0; i < warehousesDataFromFirebaseDatabase.length; i++) {
            const warehouseItem = warehousesDataFromFirebaseDatabase[i]

            if(warehouseItem.warehouse === inputParams.row.warehouse) {
              for(let j = 0; j < warehouseItem.valueList.length; j++) {
                const sensorIdItem = warehouseItem.sensorIdList[j]
                const valueItem = warehouseItem.valueList[j]

                if(!valueItem) {
                  await getUpdateRulesApi(sensorIdItem)
                }
              }
            }
          }

          // FETCH DATA
          await fetchDataFromApis()
        }
        else {
          console.log('No data available')
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }

    changeToast({
      open: true,
      severity: 'success',
      message: 'Successfully changing a rule.',
    })
  }

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      flex: 0,
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'value',
      headerName: 'Working Status',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <CustomSwitch 
              className={classes.switch}
              checked={!params.value}
              onChange={() => onCustomSwitchChange(params)}
            />
          }
          label={params.value ? 'NOT OPERATION' : 'OPERATION'}
        />
      )
    },
  ]

  useEffect(() => {
    fetchDataFromApis()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.contentWarehousesRoot}>
      {/* LOADING */}
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        <CircularProgress 
          className={classes.loading}
          size={50}
        />
      </Backdrop>

      {/* DATA GRID */}
      <CustomDataGrid 
        rows={tableData} 
        columns={columns}
      />
    </div>
  )
}

export default ContentWarehouses
