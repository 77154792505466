export const getUpdateRulesApi = async (inputSensorId) => {
  // const TAG = 'getUpdateRulesApi'

  const baseUrl = process.env.REACT_APP_BASE_API_NEW
  const completeUrl = `${baseUrl}/glico/reading/updateRule/${inputSensorId}`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
  })

  // console.log(`${TAG}, response: `, response)
  return response
}