import React, { useContext } from 'react'

// COMPONENTS
import ContentWarehouses from '../ContentWarehouses/ContentWarehouses'
import ContentDetail from '../ContentDetail/ContentDetail'
import ContentLogs from '../ContentLogs/ContentLogs'

// CONTEXTS
import { PageHomeContext } from 'contexts/PageHomeContext'

// STYLES
import useStyles from './homeContentUseStyles'

const HomeContent = () => {
  const { selectedTab } = useContext(PageHomeContext)

  const classes = useStyles()

  let selectedContent
  if(selectedTab === 0) selectedContent = <ContentWarehouses/>
  else if(selectedTab === 1) selectedContent = <ContentDetail/>
  else if(selectedTab === 2) selectedContent = <ContentLogs/>

  return (
    <div className={classes.homeContentRoot}>
      {selectedContent}
    </div>
  )
}

export default HomeContent
