// CONSTANTS
import colors from 'constants/colors'

// MUI MATERIALS
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let customTheme = createTheme({
  palette: {
    primary: {
      main: colors.dodgerblue,
    },
    secondary: {
      main: colors.brown,
    },
  },
  typography: {
    fontFamily: [
      'Poppins', 'sans-serif'
    ].join(','),
  },
})

customTheme = responsiveFontSizes(customTheme)

export default customTheme