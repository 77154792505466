// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contentLogsRoot: {
    flex: 1,
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loading: {
    color: 'white',
  },
  textInherit: {
    color: 'inherit',
    fontWeight: 'inherit',
  },
}))

export default useStyles