import React from 'react'

// IMAGES
import BackgroundTrianglesWhite from 'assets/images/background/triangles_white.png'
import LogoLacakFullWhite from 'assets/images/logo/lacak_full_white.png'

// MUI MATERIALS
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './leftSectionUseStyles'

const LeftSection = () => {
  const classes = useStyles()

  return (
    <Grid 
      item
      md={6} lg={8}
    >
      <div className={classes.leftSection}>
        <img 
          src={LogoLacakFullWhite}
          alt=''
          className={classes.logoLacak}
        />
        <img 
          src={BackgroundTrianglesWhite}
          alt=''
          className={classes.backgroundWhite}
        />
      </div>
    </Grid>
  )
}

export default LeftSection
