import React, { useState } from 'react'

// MUI MATERIALS
import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'

// UTILS
import colors from 'constants/colors'

const CustomDataGrid = styled((props) => {
  const [ pageSize, setPageSize ] = useState(10)
  
  return (
    <DataGridPro 
      {...props}
      rowsPerPageOptions={[ 10, 25, 50 ]}
      pagination
      // autoPageSize={true}
      disableSelectionOnClick
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
    />
  )
})({
  root: {
    // ROOT
    height: '100%',
    border: 'none',
    
    // SCROLL BAR
    '& .MuiDataGrid-window': {
      '&::-webkit-scrollbar': {
        width: 5,
        height: 5,
        // backgroundColor: 'transparent',
        backgroundColor: colors.whitesmokeGray,
      },
      '&::-webkit-scrollbar-thumb': {
        width: 5,
        height: 5,
        // backgroundColor: 'transparent',
        backgroundColor: colors.darkgray,
      }
    },

    // CHECKED
    '& .Mui-checked': {
      color: colors.dodgerblue,
    },
    
    // HEADER
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: colors.dodgerblue,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    // CELL
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
})

export default CustomDataGrid