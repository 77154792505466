// CONSTANTS
import colors from 'constants/colors'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  homeTabRoot: {
		width: '100%',
    height: 'fit-content',
    backgroundColor: colors.dimgray,
    marginTop: '10px',
    marginBottom: '10px',
  },
}))

export default useStyles