// UTILS
import { readUsernameFromLocalStorage } from 'utils/localStorage'

export const filterLogsDataByRegion = (inputList) => {
  let output

  const [ emailName, emailDomain ] = readUsernameFromLocalStorage().toLowerCase().split('@')
  if(emailDomain.includes('glico.com')){
    const region = emailName.split('.')[0].toLowerCase()
    output = inputList.filter(item => item['region'].replaceAll('-', '').toLowerCase().includes(region))
  }
  else {
    output = inputList
  }

  return output
}