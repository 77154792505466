import React from 'react'
import { Redirect } from 'react-router-dom'

// COMPONENTS
import LeftSection from './LeftSection/LeftSection'
import RightSection from './RightSection/RightSection'

// MUI MATERIALS
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './signInUseStyles'

// UTILS
import { readUsernameFromLocalStorage } from 'utils/localStorage'

function SignIn() {
  const classes = useStyles()

  if(readUsernameFromLocalStorage()) {
    return <Redirect to='/'/>
  }
  return (
    <div sx={classes.signInRoot}>
      <Grid container>
        <LeftSection />
        <RightSection />
      </Grid>
    </div>
  )
}

export default SignIn
