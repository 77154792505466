import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// IMAGES
import LogoGlicoWings from 'assets/images/logo/glico_wings.png'

// MU MATERIALS
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// MATERIAL UI ICONS
import IconClose from '@mui/icons-material/Close'
import IconMenu from '@mui/icons-material/Menu'
// import IconRefresh from '@mui/icons-material/Refresh'

// MATERIAL UI STYLES
import { useTheme } from '@mui/material/styles'

// STYLES
import useStyles from './headerUseStyles'

// UTILS
import {
  readUsernameFromLocalStorage,
  removeUsernameFromLocalStorage,
} from 'utils/localStorage'

const Header = () => {
  const classes = useStyles()

  const history = useHistory()

  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [ isMenuOpen, setIsMenuOpen ] = useState(true)

  const username =
  readUsernameFromLocalStorage() ?
  readUsernameFromLocalStorage() :
  'email.com'

  const onLogoutButtonIsClicked = () => {
    removeUsernameFromLocalStorage()
    history.push('/sign-in')
  }

  useEffect(() => {
    if(isXsScreen) {
      setIsMenuOpen(false)
    }
    else {
      setIsMenuOpen(true)
    }
  }, [isXsScreen])

  return (
    <div className={classes.headerRoot}>
      {/* LEFT SECTION */}
      <div className={classes.leftSection}>
        {/* GLICO WINGS LOGO */}
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={LogoGlicoWings}
            alt=''
          />
        </div>

        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes.title}
        >
          Lacak.io Sensor Management
        </Typography>

        {/* TOGGLE MENU */}
        <IconButton 
          className={classes.iconToogle}
          onClick={() => setIsMenuOpen(current => !current)}
        >
          {isMenuOpen ? <IconClose/> : <IconMenu/>}
        </IconButton>
      </div>

      {/* RIGHT SECTION */}
      <div 
        className={
          isMenuOpen ?
          `${classes.rightSection}` :
          classes.hideSection
        }
      >
        {/* REFRESH ICON */}
        {/* <div className={classes.iconRefreshContainer}>
          <IconButton 
            className={classes.iconRefresh}
            // TODO: ADD ON CLICK FUNCTION HERE
          >
            <IconRefresh/>
          </IconButton>
        </div> */}

        {/* PROFILE CONTAINER */}
        <div className={classes.profileContainer}>
          {/* EMAIL */}
          <Typography
            variant='subtitle1'
            className={classes.text}
          >
            {`Hello ${username}`}
          </Typography>

          {/* LOGOUT */}
          <Button 
            variant='text'
            color='secondary'
            className={{...classes.text, ...classes.logout}}
            onClick={onLogoutButtonIsClicked}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
