import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import FilterView from './FilterView'
import ListView from './ListView'
import TableView from './TableView'

// CONTEXTS
import { PageHomeContext } from 'contexts/PageHomeContext'

// MUI MATERIALS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

// SERVICES
import { detailGetTrackerSensorsApi } from 'services/home/getTrackerSensorsApi'
import { detailGetTrackerRulesApi } from 'services/home/getTrackerRulesApi'
import { getUpdateRulesApi } from 'services/home/getUpdateRulesApi'

// STYLES
import useStyles from './contentDetailUseStyles'

// UTILS
import { combineTrackerSensorsAndRulesData } from 'utils/home/combineTrackerSensorsAndRulesData'
import { filterCombinedDataByEmail } from 'utils/home/filterCombinedDataByEmail'

const ContentDetail = () => {
  const { changeToast, breakpointType } = useContext(PageHomeContext)

  const classes = useStyles()
  
  const [ isLoading, setIsLoading ] = useState(false)

  // TABLE AND LIST VIEW DATA
  const [ tableData, setTableData ] = useState([])
  const [ filteredData, setFilteredData ] = useState([])

  // FILTER
  const [ search, setSearch ] = useState('')
  const [ filterTemperature, setFilterTemperature ] = useState(null)
  const [ filterPower, setFilterPower ] = useState(null)
  const [ filterStatus, setFilterStatus ] = useState(null)
  const [ filterOperation, setFilterOperation ] = useState(null)

  const fetchDataFromApis = async () => {
    setIsLoading(true)

    const trackersData = await detailGetTrackerSensorsApi()
    // console.log(trackersData)
    if(!trackersData.status) {
      const rulesData = await detailGetTrackerRulesApi()
      // console.log(rulesData)
      if(!rulesData.status) {
        const combinedData = combineTrackerSensorsAndRulesData(trackersData, rulesData)
        // console.log(combinedData)
        const filteredData = filterCombinedDataByEmail(combinedData)
        // console.log(filteredData)
        setTableData(filteredData)
      }
      else {
        changeToast({
          open: true,
          severity: 'error',
          message: rulesData.error,
        })
      }
    }
    else {
      changeToast({
        open: true,
        severity: 'error',
        message: trackersData.error,
      })
    }

    setIsLoading(false)
  }

  const fetchGetUpdateRulesApi = async (inputSensorId) => {
    setIsLoading(true)

    const response = await getUpdateRulesApi(inputSensorId)
    if(response.status === 200) {
      changeToast({
        open: true,
        severity: 'success',
        message: 'Successfully changing a rule.',
      })

      fetchDataFromApis()
    }
    else {
      changeToast({
        open: true,
        severity: 'error',
        message: response.error,
      })
    }
    
    setIsLoading(false)
  }

  const filterData = () => {
    let newData = []

    tableData.forEach(item => {
      let pass = true

      if(
        search === '' ||
        (item.trackerGroup.toLowerCase().includes(search.toLowerCase())) ||
        (item.trackerLabel.toLowerCase().includes(search.toLowerCase())) ||
        (item.freezerName.toLowerCase().includes(search.toLowerCase()))
      ) pass = true
      else pass = false

      if(
        (pass && filterTemperature === null) ||
        (pass && filterTemperature === 0 && item.sensorValue <= -15.0) ||
        (pass && filterTemperature === 1 && item.sensorValue > -15.0)
      ) pass = true
      else pass = false

      if(
        (pass && filterPower === null) ||
        (pass && filterPower === 0 && item.power === 'OFF') ||
        (pass && filterPower === 1 && item.power === 'ON')
      ) pass = true
      else pass = false

      if(
        (pass && filterStatus === null) ||
        (pass && filterStatus === 0 && item.status === 'NOT OK') ||
        (pass && filterStatus === 1 && item.status === 'OK')
      ) pass = true
      else pass = false

      if(
        (pass && filterOperation === null) ||
        (pass && filterOperation === 0 && item.suspended) ||
        (pass && filterOperation === 1 && !item.suspended)
      ) pass = true
      else pass = false

      if(pass) newData.push(item)
    })

    setFilteredData(newData)
  }

  useEffect(() => {
    fetchDataFromApis()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    filterData()
  }, [
    search, 
    filterTemperature, 
    filterPower, 
    filterStatus, 
    filterOperation
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.contentDetailRoot}>
      {/* LOADING */}
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        <CircularProgress 
          className={classes.loading}
          size={50}
        />
      </Backdrop>

      {/* FILTER */}
      <FilterView
        search={search}
        setSearch={setSearch}
        filterTemperature={filterTemperature}
        setFilterTemperature={setFilterTemperature}
        filterPower={filterPower}
        setFilterPower={setFilterPower}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterOperation={filterOperation}
        setFilterOperation={setFilterOperation}
      />

      {/* CONTENT */}
      {(breakpointType === 'xs' || breakpointType === 'sm') ?
      // XS TO SM SCREEN SIZE VIEW
      <ListView
        inputData={
          (
            search === '' &&
            filterTemperature === null && 
            filterPower === null && 
            filterStatus === null &&
            filterOperation === null
          ) ?
          tableData : 
          filteredData
        }
        fetchGetUpdateRulesApi={fetchGetUpdateRulesApi}
      /> :        
      // MD TO XL SCREEN SIZE VIEW
      <TableView 
        rows={
          (
            search === '' &&
            filterTemperature === null && 
            filterPower === null && 
            filterStatus === null &&
            filterOperation === null
          ) ?
          tableData : 
          filteredData
        } 
        fetchGetUpdateRulesApi={fetchGetUpdateRulesApi}
      />}
    </div>
  )
}

export default ContentDetail
