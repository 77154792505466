import React, { createContext, useState } from 'react'

// FIREBASE
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

// MATERIAL UI CORES
import useMediaQuery from '@mui/material/useMediaQuery'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const firebaseDatabase = getDatabase(firebaseApp)

const PageHomeContext = createContext()

const PageHomeContextProvider = (props) => {
  // NUMBER
  const [ selectedTab, setSelectedTab ] = useState(0)

  // OBJECT
  const [ toast, setToast ] = useState(null)
  
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.only('sm'))
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.only('md'))
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.only('lg'))
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.only('xl'))

  let breakpointType
  isXsScreen && (breakpointType = 'xs')
  isSmScreen && (breakpointType = 'sm')
  isMdScreen && (breakpointType = 'md')
  isLgScreen && (breakpointType = 'lg')
  isXlScreen && (breakpointType = 'xl')

  const changeSelectedTab = (inputEvent, inputValue) => {
    setSelectedTab(inputValue)
  }

  const changeToast = (inputObject) => {
    setToast(inputObject)
  }

  console.log(breakpointType)

  return (
    <PageHomeContext.Provider
      value={{
        firebaseDatabase,
        breakpointType,
        selectedTab, changeSelectedTab,
        toast, changeToast,
      }}
    >
      {props['children']}
    </PageHomeContext.Provider>
  )
}

export { PageHomeContextProvider, PageHomeContext }