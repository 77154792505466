// CONSTANTS
import colors from 'constants/colors'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid darkgray',
    display: 'flex',
  },
  listResultText: {
    marginBottom: 15,
  },
  listItemNumber: {
    minWidth: 30,
    width: 30,
    paddingTop: 10,
    color: 'white',
    backgroundColor: colors.lightBlue,
    textAlign: 'center',
    borderRadius: '5px 0px 0px 5px',
  },
  backgroundTextRed: {
    backgroundColor: colors.pink,
  },
  listItemContent: {
    width: '100%',
    padding: 10,
  },
  listItemFlex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid darkgray',
    borderBottom: '1px solid darkgray',
    padding: '10px 0px',
    marginTop: 10,
    '&:last-child': {
      border: 'none',
      marginTop: 0,
      padding: '10px 10px 0px 0px',
      justifyContent: 'flex-end',
    },
  },
  listItemGrayText: {
    fontSize: 14,
    color: 'slategray',
  },
  listItemBlackText: {
    color: 'black',
  },
  listItemSwitch: {
    marginLeft: 10,
    padding: 0,
  },
}))

export default useStyles