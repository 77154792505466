// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contentWarehousesRoot: {
    flex: 1,
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loading: {
    color: 'white',
  },
  switch: {
    margin: '0px 10px',
  },
}))

export default useStyles