import React from 'react'

// CUSTOM COMPONENTS
import CustomSwitch from 'components/Customs/CustomSwitch'

// MUI MATERIALS
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// STYTLES
import useStyles from './listViewUseStyles'

const ListView = (props) => {
  const {
    inputData,
    fetchGetUpdateRulesApi,
  } = props

  const classes = useStyles()

  return (
    <div>
      {/* RESULT TEXT */}
      <Typography
        variant='subtitle2'
        align='center'
        className={classes.listResultText}
      >
        {`Result: ${inputData.length} item${inputData.length === 1 ? '' : 's'}`}
      </Typography>

      {/* LIST VIEW */}
      <Grid 
        container 
        spacing='15px'
      >
        {inputData.length > 0 &&
        inputData.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12} sm={6}
          >
            <div className={classes.listItemRoot}>
              {/* NUMBER */}
              <Typography
                variant='subtitle2'
                className={
                  (item.sensorValue > -15.0 ||
                  item.power === 'OFF' ||
                  item.status === 'NOT OK' ) ?
                  `${classes.listItemNumber} ${classes.backgroundTextRed}` :                
                  classes.listItemNumber
                }
              >
                {item.no}
              </Typography>

              {/* ITEM CONTENT */}
              <div className={classes.listItemContent}>
                {/* TOP CONTENT */}
                {[
                  {
                    title: 'Warehouse: ',
                    value: item.trackerGroup,
                  },
                  {
                    title: 'Hub: ',
                    value: item.trackerLabel,
                  },
                  {
                    title: 'Freezer: ',
                    value: item.freezerName,
                  },
                ].map((topContentItem, topContentIndex) => (
                  <Typography
                    key={topContentIndex}
                    variant='subtitle2'
                    className={classes.listItemGrayText}
                  >
                    {topContentItem.title} <span className={classes.listItemBlackText}>
                      {topContentItem.value}
                    </span>
                  </Typography>
                ))}
                
                {/* BOTTOM CONTENT */}
                <div className={classes.listItemFlex}>
                  {[
                    {
                      title: 'Temp. (C):',
                      value: item.sensorValue,
                    },
                    {
                      title: 'Power:',
                      value: item.power,
                    },
                    {
                      title: 'Status:',
                      value: item.status,
                    },
                  ].map((bottomContentItem, bottomContentIndex) => (
                    <div key={bottomContentIndex}>
                      <Typography
                        variant='subtitle2'
                        className={classes.listItemGrayText}
                        align='center'
                      >
                        {bottomContentItem.title}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        className={
                          ((bottomContentIndex === 0 && bottomContentItem.value > -15.0) ||
                          (bottomContentIndex === 1 && bottomContentItem.value === 'OFF') ||
                          (bottomContentIndex === 2 && bottomContentItem.value === 'NOT OK')) ?
                          classes.textRed :
                          classes.listItemBlackText
                        }
                        align='center'
                      >
                        {bottomContentItem.value}
                      </Typography>
                    </div>
                  ))}
                </div>

                {/* SWITCH */}
                <div className={classes.listItemFlex}>
                  <FormControlLabel
                    control={
                      <CustomSwitch 
                        className={classes.listItemSwitch}
                        checked={!item.suspended}
                        onChange={() => fetchGetUpdateRulesApi(item.id)}
                      />
                    }
                    labelPlacement='start'
                    label={item.suspended ? 'NOT OPERATION' : 'OPERATION'}
                  />
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
    )
}

export default ListView