export const getWarehouseAndSuspendedList = (inputData) => {
  let warehouseList = []
  let output = []
  inputData.forEach(item => {
    if(!warehouseList.includes(item.trackerGroup)) {
      warehouseList.push(item.trackerGroup)
      output.push({
        warehouse: item.trackerGroup,
        valueList: [],
        sensorIdList: [],
      })
    }
  })
  inputData.forEach(item => {
    warehouseList.forEach((warehouseItem, warehouseIndex) => {
      if(item.trackerGroup === warehouseItem) {
        output[warehouseIndex].valueList.push(item.suspended)
        output[warehouseIndex].sensorIdList.push(item.id)
      }
    })
  })
  output.forEach((item, index) => {
    item.id = index + 1
    item.value = !item.valueList.includes(false)
  })
  return output
}