// IMAGES
import BackgroundLeaf from 'assets/images/background/horizontal_white_leaf.svg'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

// UTILS 
import colors from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  headerRoot: {
		display: 'flex',
    alignItems: 'center',
		width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 70,
    backgroundColor: colors.brown,
    paddingLeft: 20,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 10,
      width: '100%',
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    height: 'fit-content',
    backgroundColor: 'white',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      boxShadow: `0px 3px 5px ${colors.boxShadow}`,
    },
  },
  hideSection: {
    display: 'none',
  },
  logoContainer: {
    backgroundImage: `url(${BackgroundLeaf})`,
    height: '100%',
    width: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 70,
  },
  title: {
    color: 'white',
    marginLeft: 20,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  iconToogle: {
    display: 'none',
    marginLeft: 'auto',
    marginRight: 10,
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      display: 'inline',
    },
  },
  iconRefreshContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
    height: 70,
    backgroundColor: colors.brown,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 'unset',
      backgroundColor: 'white',
      padding: '0px 0px 0px 20px',
    },
  },
  iconRefresh: {
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      color: colors.brown,
    },
  },
  profileContainer: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150,
    padding: '0px 20px',
    [theme.breakpoints.only('xs')]: {
      // padding: '0px 20px 0px 0px',
    },
  },
  text: {
    color: 'black',
    textAlign: 'center',
    lineHeight: 1.5,
  },
}))

export default useStyles