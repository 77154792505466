export const postSignInApi = async (
  inputUsername, 
  inputPassword,
) => {
  const completeUrl = `${process.env.REACT_APP_BASE_API_OLD}/api/auth/login`

  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'username': inputUsername,
      'password': inputPassword,
    })
  })

  return response
}