const colors = {
  brown: '#A83135', // BROWN RED
  darkgray: '#A7A8BB', // GRAY
  dimgray: '#696969', // DARK GRAY
  dodgerblue: '#179FD8', // BLUE
  lightBlue: '#00B0FF', // LIGHT BLUE
  pink: '#F50057', // PINK
  whitesmokeGray: '#F2F3F7', // LIGHT GRAY

  // BOX SHADOW
  boxShadow: 'rgba(145, 158, 171, 0.3)', // LIGHT
}

export default colors