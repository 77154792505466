import React, { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Customs/CustomDataGrid'

// FIREBASE
import { getDatabase, ref, onValue} from 'firebase/database'

// MU MATERIALS
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './contentLogsUseStyles'

// UTILS
import { formatLogsData } from 'utils/home/formatLogsData'
import { filterLogsDataByRegion } from 'utils/home/filterLogsDataByRegion'
import { convertStringTimeStampIntoStringDate } from 'utils/formatDate'

const ContentLogs = () => {
  const classes = useStyles()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ tableData, setTableData ] = useState([])

  const columns = [
    {
      field: 'timeStamp',
      headerName: 'Date',
      flex: 1,
      minWidth: 275,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography 
          variant='subtitle2'
          className={classes.textInherit}
        >
          {convertStringTimeStampIntoStringDate(params.value)}
        </Typography>
      )
    },
    {
      field: 'freezerName',
      headerName: 'Freezer Name',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'region',
      headerName: 'Warehouse',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'previousValue',
      headerName: 'Previous Value',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'newValue',
      headerName: 'New Value',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 300,
      headerAlign: 'center',
      align: 'center',
    },
  ]

  const fetchReadLogsDataFirebase = () => {
    setIsLoading(true)

    const db = getDatabase()
    const logsRef = ref(db, 'glico-wings-database')
    onValue(logsRef, (snapshot) => {
      const rawData = snapshot.val().logs
      const formatedData = formatLogsData(rawData)
      const filteredData = filterLogsDataByRegion(formatedData)
      
      setTableData(filteredData.reverse())
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchReadLogsDataFirebase()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className={classes.contentLogsRoot}>
      {/* LOADING */}
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        <CircularProgress 
          className={classes.loading}
          size={50}
        />
      </Backdrop>

      {/* DATA GRID */}
      <CustomDataGrid 
        rows={tableData} 
        columns={columns}
      />
    </div>
  )
}

export default ContentLogs
