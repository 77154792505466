// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contentDetailRoot: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loading: {
    color: 'white',
  },
}))

export default useStyles