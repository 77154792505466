// MUI MATERIALS
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const StyledTab = styled((props) => <Tab {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: 'white',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
)

export default StyledTab