export const combineTrackerSensorsAndRulesData = (
  inputTrackerSensorsData,
  inputTrackerRulesData,
) => {
  let finalData = []
  for(let i = 0; i < inputTrackerSensorsData.length; i++){
    for(let j = 0; j < inputTrackerSensorsData.length; j++){
      if(inputTrackerSensorsData[i].freezer.includes(inputTrackerRulesData[j].freezerName)){
        let newItem = { ...inputTrackerSensorsData[i], ...inputTrackerRulesData[j] }
        finalData.push(newItem)
      }
    }
  }

  return finalData
}