import React from 'react'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Customs/CustomDataGrid'
import CustomSwitch from 'components/Customs/CustomSwitch'

// MUI MATERIALS
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './tableViewUseStyles'

const TableView = (props) => {
  const {
    rows,
    fetchGetUpdateRulesApi,
  } = props

  const classes = useStyles()

  const columns = [
    {
      field: 'no',
      headerName: 'No.',
      flex: 1,
      minWidth: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'trackerGroup',
      headerName: 'Warehouse',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'trackerLabel',
      headerName: 'Hub',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'freezerName',
      headerName: 'Freezer',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'battery',
      headerName: 'Battery (%)',
      flex: 1,
      minWidth: 125,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sensorValue',
      headerName: 'Temperature (C)',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={params.value > -15.0 ? classes.textRed : classes.textInherit}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: 'power',
      headerName: 'Power',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={params.value === 'OFF' ? classes.textRed : classes.textInherit}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 125,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={params.value === 'NOT OK' ? classes.textRed : classes.textInherit}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: 'suspended',
      headerName: 'Working Status',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <CustomSwitch 
              className={classes.switch}
              checked={!params.value}
              onChange={() => fetchGetUpdateRulesApi(params.id)}
            />
          }
          label={params.value ? 'NOT OPERATION' : 'OPERATION'}
        />
      )
    },
  ]

  return (
    <CustomDataGrid 
      rows={rows} 
      columns={columns}
      disableColumnMenu={true}
    />
  )
}

export default TableView