// CONSTANTS
import colors from 'constants/colors'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	leftSection: {
		width: '100%',
		height: '100vh',
		position: 'relative',
		backgroundColor: colors.dodgerblue,
		minHeight: 650,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	logoLacak: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	backgroundWhite: {
		width: '100%',
		height: '100%',
		mixBlendMode: 'multiply',
	},
}))

export default useStyles