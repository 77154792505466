// UTILS
import { readUsernameFromLocalStorage } from 'utils/localStorage'

export const filterCombinedDataByEmail = (inputData) => {
  // CHECK IF THE EMAIL IS EITHER FROM CENTER OR REGION
  if(readUsernameFromLocalStorage() !== null){
    const [ emailName, emailDomain ] = readUsernameFromLocalStorage().toLowerCase().split('@')
    
    if(emailDomain.includes('glico.com')){
      // REPLACE ALL "DASH" ("-") CHARACTERS FROM SOME VALUES
      for(let i = 0; i < inputData.length; i++){
        const item = inputData[i]
        item.trackerGroup = item.trackerGroup.replaceAll('-', '')
        item.trackerLabel = item.trackerLabel.replaceAll('-', '')
      }

      // FILTER THE NEW ARRAY BY REGION
      const region = emailName.split('.')[0].toLowerCase()
      inputData = inputData.filter(item => item.trackerGroup.toLowerCase().includes(region))

      // UPDATE THE ORDER OF NUMBER
      for(let i = 0; i < inputData.length; i++){
        const item = inputData[i]
        item.no = i + 1
      }
    }
  }

  return inputData
}