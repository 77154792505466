// CONSTANTS
import colors from 'constants/colors'

// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  textRed: {
    color: colors.pink,
  },
  textInherit: {
    color: 'inherit',
    fontWeight: 'inherit',
  },
  switch: {
    margin: '0px 10px',
  },
}))

export default useStyles