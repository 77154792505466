// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  filterViewRoot: {
    marginBottom: 15,
  },
  filterSearch: {
    marginBottom: 15,
    [theme.breakpoints.only('lg')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.only('xl')]: {
      marginBottom: 0,
    },
  },
}))

export default useStyles