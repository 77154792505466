// MATERIAL UI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  signInRoot: {
		display: 'flex',
		flex: 1,
		width: '100vw',
		height: '100vh',
  },
}))

export default useStyles