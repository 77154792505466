export const formatLogsData = (inputObject) => {
  let keys = []
  for(const key in inputObject){
    keys.push(key)
  }

  let output = []
  for(let i = 0; i < keys.length; i++){
    let newItem = inputObject[keys[i]]
    newItem.timeStamp = keys[i]
    newItem.id = i + 1
    output.push(newItem)
  }

  return output
} 