// MUI MATERIALS
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const StyledTextField = styled((props) => (
  <TextField {...props}/>
))({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: 12.5,
    paddingLeft: 0,
  },
})

export default StyledTextField