export const getTrackerRulesApi = async () => {
  // const TAG = 'getTrackerRulesApi'

  const baseUrl = process.env.REACT_APP_BASE_API_NEW
  const completeUrl = `${baseUrl}/glico/reading/get_rules`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
  })

  // console.log(`${TAG}, response: `, response)
  const responseJson = await response.json()
  // console.log(`${TAG}, reponseJson: `, responseJson)
  return responseJson
}

export const detailGetTrackerRulesApi = async () => {
  const rawData = await getTrackerRulesApi()
  let newData = []

  // ADD ALL ITEMS TO A NEW ARRAY WHICH EACH TYPE PROPERTY IS 'SENSOR_RANGE'
  for(let i = 0; i < rawData.length; i++){
    const rawDataItem = rawData[i]
    
    // 1. CHECK IF THE NEW ARRAY IS EMPTY AND THE TYPE PROPERTY IS 'SENSOR_RANGE'
    if(newData.length === 0 && rawDataItem.type === 'sensor_range'){
      const freezerName = rawDataItem.name.split('(')[1].split(' -')[0] // EXAMPLE: "Freezer A1"
      let newItem = {}
      newItem.freezerName = freezerName
      newItem.suspended = rawDataItem.suspended
      newItem.workingStatus = rawDataItem.suspended ? 'NOT OPERATION' : 'OPERATION'
      newItem.sensor_id = rawDataItem.extended_params.sensor_id
      newItem.id = rawDataItem.extended_params.sensor_id
      newData.push(newItem)
    }
    else if(newData.length !== 0 && rawDataItem.type === 'sensor_range'){
      // 2. CHECK IF THE FREEZER NAME IS AVAILABLE IN THE ARRAY
      let isNewItem = 0
      for(let j = 0; j < newData.length; j++){
        let newDataItem = newData[j]
        const freezerName = rawDataItem.name.split('(')[1].split(' -')[0] // EXAMPLE: "Freezer A1"
        if(newDataItem.freezerName === freezerName){
          isNewItem++
        }
      }

        // 3. ADD THE ITEM IF THE FREEZER NAME PROPERTY IS NOT AVAILABLE IN THE ARRAY
      if(isNewItem === 0){
        const freezerName = rawDataItem.name.split('(')[1].split(' -')[0] // EXAMPLE: "Freezer A1"
        let newItem = {}
        newItem.freezerName = freezerName
        newItem.suspended = rawDataItem.suspended
        newItem.workingStatus = rawDataItem.suspended ? 'NOT OPERATION' : 'OPERATION'
        newItem.sensor_id = rawDataItem.extended_params.sensor_id
        newItem.id = rawDataItem.extended_params.sensor_id
        newData.push(newItem)
      }
    }
  }

  return newData
}